body {
  margin: 0;
  font-family: 'Avenir Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url("../fonts/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Regular';
  src: url("../fonts/Avenir-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'DIN';
  src: url("../fonts/D-DIN.ttf") format("truetype");
}

@font-face {
  font-family: 'DIN Bold';
  src: url("../fonts/D-DIN-Bold.ttf") format("truetype");
}

p {
  color: #646464;
  font-family: Avenir Regular !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bolder !important;
}